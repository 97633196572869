<template>
  <b-modal id="payment-method-modal"
           ref="payment-method-modal"
           size="lg"

           v-on="$listeners"
           @hidden="onClose"
  >
    <template v-slot:modal-title>
      <h3 class="m-0 p-0 pb-3">
        Manage Payment Method
      </h3>
    </template>
    <template v-slot:modal-footer>
      <payment-method-form v-bind="$props" v-on="$listeners" @badCardIssuer="emitBadCardIssuer" />
    </template>
  </b-modal>
</template>

<script>
import PaymentMethodForm from './PaymentMethodForm'

// This is primarily a pass through that houses the form in a modal
export default {
  name: 'PaymentMethodModal',
  components: {
    PaymentMethodForm,
  },
  props: {
    fromPaymentMethodsPage: Boolean,
    selectedAddress: null,
  },
  methods: {
    emitBadCardIssuer() {
      this.$emit('badCardIssuer')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
<style scoped lang="scss">
  ::v-deep .modal-content {
    background-color: transparent !important;
    padding: 0 !important;
  }

  ::v-deep .modal-body {
    padding: 0 !important;
  }

  ::v-deep .modal-footer {
    padding: 0 !important;
  }
</style>
